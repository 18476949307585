import React, { useState, useEffect, useRef, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { API_ENDPOINTS, getAxiosInstance } from '../../config/api';

const ThinkingAnimation = () => (
  <div className="chat chat-start animate-fade-in">
    <div className="chat-image">
      <AIAvatar />
    </div>
    <div className="chat-bubble bg-base-200 flex items-center gap-2 min-h-12">
      <div className="flex gap-1">
        <span className="w-2 h-2 rounded-full bg-current animate-bounce" style={{ animationDelay: '0ms' }}></span>
        <span className="w-2 h-2 rounded-full bg-current animate-bounce" style={{ animationDelay: '150ms' }}></span>
        <span className="w-2 h-2 rounded-full bg-current animate-bounce" style={{ animationDelay: '300ms' }}></span>
      </div>
    </div>
  </div>
);

const AIAvatar = () => (
  <div className="avatar flex-shrink-0">
    <div className="w-8 h-8 rounded-full bg-gradient-to-br from-primary/90 via-secondary/90 to-primary/90 flex items-center justify-center animate-gradient bg-[length:200%_200%]">
    </div>
  </div>
);

const CopyButton = ({ content }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(content);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <button
      onClick={handleCopy}
      className="btn btn-ghost btn-xs gap-1 opacity-50 hover:opacity-100"
      title="Копировать сообщение"
    >
      {copied ? (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
          </svg>
          <span>Скопировано</span>
        </>
      ) : (
        <>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
            <path d="M7 3.5A1.5 1.5 0 018.5 2h3.879a1.5 1.5 0 011.06.44l3.122 3.12A1.5 1.5 0 0117 6.622V12.5a1.5 1.5 0 01-1.5 1.5h-1v-3.379a3 3 0 00-.879-2.121L10.5 5.379A3 3 0 008.379 4.5H7v-1z" />
            <path d="M4.5 6A1.5 1.5 0 003 7.5v9A1.5 1.5 0 004.5 18h7a1.5 1.5 0 001.5-1.5v-5.879a1.5 1.5 0 00-.44-1.06L9.44 6.439A1.5 1.5 0 008.378 6H4.5z" />
          </svg>
          <span>Копировать</span>
        </>
      )}
    </button>
  );
};

const MarkdownRenderer = React.memo(({ content }) => {
  const preprocessContent = (text) => {
    if (!text) return '';
    
    // Обработка нумерованных списков и других форматов
    const lines = text.split('\n');
    let inList = false;
    const processedLines = lines.map((line, index) => {
      // Нумерованные списки
      const numberListMatch = line.match(/^(\d+)\.\s*(.+)$/);
      if (numberListMatch) {
        inList = true;
        // Добавляем дополнительный перенос строки перед списком, если это первый элемент
        const prefix = (!inList && index > 0) ? '\n' : '';
        return `${prefix}${numberListMatch[1]}. ${numberListMatch[2]}`;
      }
      
      // Если строка не является элементом списка, сбрасываем флаг
      if (line.trim() === '') {
        inList = false;
      }
      
      return line;
    });
    
    return processedLines.join('\n');
  };

  const processedContent = useMemo(() => preprocessContent(content), [content]);

  return (
    <div className="prose prose-sm max-w-none">
      <ReactMarkdown
        key={processedContent}
        remarkPlugins={[remarkGfm]}
        components={{
          h1: ({ node, ...props }) => <h1 className="text-2xl font-bold mt-6 mb-4" {...props} />,
          h2: ({ node, ...props }) => <h2 className="text-xl font-bold mt-5 mb-3" {...props} />,
          h3: ({ node, ...props }) => <h3 className="text-lg font-bold mt-4 mb-2" {...props} />,
          h4: ({ node, ...props }) => <h4 className="text-base font-semibold mt-3 mb-2" {...props} />,
          
          ul: ({ node, ...props }) => <ul className="list-disc ml-6 my-4 space-y-2" {...props} />,
          ol: ({ node, ...props }) => (
            <ol className="list-decimal ml-6 my-2 space-y-1 marker:text-base-content" style={{ counterReset: 'list-counter' }} {...props} />
          ),
          li: ({ node, ...props }) => {
            const isNumberedList = node.parent?.type === 'list' && node.parent?.ordered;
            return (
              <li 
                className="pl-1 my-0" 
                style={isNumberedList ? { counterIncrement: 'list-counter' } : {}}
                {...props} 
              />
            );
          },
          
          p: ({ node, children, ...props }) => {
            // Проверяем, является ли параграф частью списка
            const isListItem = node?.parent?.type === 'listItem';
            return isListItem ? (
              <span {...props}>{children}</span>
            ) : (
              <p className="my-3 leading-relaxed" {...props}>{children}</p>
            );
          },
          
          a: ({ node, ...props }) => (
            <a 
              className="text-primary hover:text-primary-focus underline hover:no-underline" 
              target="_blank" 
              rel="noopener noreferrer" 
              {...props} 
            />
          ),
          
          code: ({ node, inline, className, children, ...props }) => {
            const match = /language-(\w+)/.exec(className || '');
            const language = match ? match[1] : '';
            
            return inline ? (
              <code className="bg-base-300/50 px-1.5 py-0.5 rounded text-sm font-mono text-base-content break-all" {...props}>
                {children}
              </code>
            ) : (
              <div className="relative my-4">
                {language && (
                  <div className="absolute right-3 top-3 text-xs text-base-content/50 font-mono">
                    {language}
                  </div>
                )}
                <pre className="bg-base-300/50 rounded-lg">
                  <code className={`block p-4 overflow-x-auto font-mono text-sm text-base-content whitespace-pre-wrap break-words ${className || ''}`} {...props}>
                    {children}
                  </code>
                </pre>
              </div>
            );
          },
          
          pre: ({ children }) => <>{children}</>,
          hr: ({ node, ...props }) => <hr className="my-6 border-base-300" {...props} />,
          strong: ({ node, ...props }) => <strong className="font-bold text-base-content" {...props} />,
          em: ({ node, ...props }) => <em className="italic text-base-content" {...props} />,
          
          table: ({ node, ...props }) => (
            <div className="my-4 overflow-x-auto">
              <table className="table table-compact w-full border-collapse" {...props} />
            </div>
          ),
          th: ({ node, ...props }) => <th className="bg-base-300/50 px-4 py-2 text-left font-semibold" {...props} />,
          td: ({ node, ...props }) => <td className="px-4 py-2 border-t border-base-300" {...props} />,
          
          blockquote: ({ node, ...props }) => (
            <blockquote className="border-l-4 border-base-300 pl-4 my-4 italic text-base-content/80" {...props} />
          ),
        }}
      >
        {processedContent}
      </ReactMarkdown>
    </div>
  );
}, (prevProps, nextProps) => {
  // Принудительно обновляем компонент при изменении контента
  return prevProps.content === nextProps.content;
});

const StreamingMessage = ({ content }) => (
  <MarkdownRenderer content={content} />
);

const LiveMarkdown = ({ content }) => (
  <MarkdownRenderer content={content} />
);

const ChatWindow = ({ chatId, onUpdateChatTitle, onCreateChat, setActiveChat }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isThinking, setIsThinking] = useState(false);
  const [shownMessageIds, setShownMessageIds] = useState(new Set());
  const [animatedMessageIds] = useState(new Set());
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const textareaRef = useRef(null);
  const [, forceUpdate] = useState();
  const [streamingMessage, setStreamingMessage] = useState(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const { scrollHeight, clientHeight } = chatContainerRef.current;
      chatContainerRef.current.scrollTop = scrollHeight - clientHeight;
    }
  };

  useEffect(() => {
    if (chatId) {
      fetchMessages();
    }
  }, [chatId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const fetchMessages = async () => {
    try {
      const api = getAxiosInstance();
      const response = await api.get(API_ENDPOINTS.MESSAGES(chatId));
      const data = response.data;
      setMessages(data);
      
      setShownMessageIds(prev => {
        const newSet = new Set(prev);
        data.forEach(msg => {
          if (msg.id) newSet.add(msg.id);
        });
        return newSet;
      });
    } catch (error) {
      console.error('Ошибка загрузки сообщений:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    const userMessage = {
      content: newMessage,
      role: 'user',
      tempId: Date.now(),
    };

    setMessages(prev => [...prev, userMessage]);
    setNewMessage('');
    if (textareaRef.current) {
      textareaRef.current.style.height = '3rem';
    }
    setIsThinking(true);
    setIsLoading(true);

    try {
      const api = getAxiosInstance();
      const response = await api.post(API_ENDPOINTS.MESSAGES(chatId), {
        content: userMessage.content,
        role: 'user',
      });

      if (messages.length === 0) {
        const truncatedTitle = userMessage.content.length > 50 
          ? userMessage.content.substring(0, 47) + '...' 
          : userMessage.content;
        
        await api.patch(`${API_ENDPOINTS.CHATS}/${chatId}`, {
          title: truncatedTitle
        });
        
        if (onUpdateChatTitle) {
          onUpdateChatTitle(chatId, truncatedTitle);
        }
      }

      const data = response.data;
      
      const aiMessage = {
        content: data.content,
        role: 'assistant',
        id: Date.now(),
        timestamp: Date.now()
      };
      
      setMessages(prev => [...prev, aiMessage]);

    } catch (error) {
      console.error('Ошибка отправки сообщения:', error);
      setMessages(prev => prev.filter(msg => msg.tempId !== userMessage.tempId));
    } finally {
      setIsLoading(false);
      setIsThinking(false);
    }
  };



  if (!chatId) {
    return (
      <div className="flex-1 flex flex-col items-center justify-center p-4 bg-base-100 min-h-screen">
        <div className="text-center max-w-md">
          <div className="mb-6 opacity-50">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 mx-auto">
              <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
            </svg>
          </div>
          <h2 className="text-xl font-semibold mb-2 text-base-content">Начните общение</h2>
          <p className="text-base-content/60">
            Создайте новый чат или выберите существующий из списка слева
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <div 
        ref={chatContainerRef}
        className="flex-1 overflow-y-auto"
        style={{
          scrollbarWidth: 'thin',
          scrollbarColor: 'var(--scrollbar-thumb) transparent'
        }}
      >
        <div className="max-w-3xl mx-auto px-4 py-2 md:py-4 pb-24 md:pb-4">
          {messages.map((message) => (
            <MessageComponent 
              key={message.id || message.tempId}
              message={message}
              isUser={message.role === 'user'}
            />
          ))}
          
          {isThinking && <ThinkingAnimation />}
          <div ref={messagesEndRef} className="h-4" />
        </div>
      </div>

      <div className="fixed bottom-0 left-0 right-0 md:static p-4 bg-base-100 z-10">
        <div className="max-w-3xl mx-auto">
          <form onSubmit={handleSubmit} className="flex gap-2 relative shadow-lg rounded-lg bg-base-200">
            <textarea
              ref={textareaRef}
              value={newMessage}
              onChange={(e) => {
                setNewMessage(e.target.value);
                e.target.style.height = 'auto';
                e.target.style.height = Math.min(e.target.scrollHeight, 200) + 'px';
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && (e.ctrlKey || e.shiftKey)) {
                  e.preventDefault();
                  setNewMessage(prev => prev + '\n');
                  setTimeout(() => {
                    e.target.style.height = 'auto';
                    e.target.style.height = Math.min(e.target.scrollHeight, 200) + 'px';
                  }, 0);
                } else if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey) {
                  e.preventDefault();
                  handleSubmit(e);
                }
              }}
              placeholder="Введите сообщение..."
              className="textarea flex-1 bg-transparent border-none focus:outline-none min-h-[3rem] h-[3rem] px-4 pr-12 py-2 overflow-y-auto"
              disabled={isLoading}
              rows={1}
              style={{
                resize: 'none',
                scrollbarWidth: 'thin',
                scrollbarColor: 'var(--scrollbar-thumb) transparent'
              }}
            />
            <div className="absolute right-0 inset-y-0 flex items-center pr-2">
              <button
                type="submit"
                className={`btn btn-ghost h-10 w-10 p-0 ${isLoading ? 'loading' : ''}`}
                disabled={isLoading}
              >
                {isLoading ? (
                  <span className="loading loading-spinner"></span>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                    <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                  </svg>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const MessageComponent = ({ message, isUser }) => {
  const messageKey = `${message.id || message.tempId}-${message.content}-${message.timestamp || Date.now()}`;
  
  return (
    <div key={messageKey} className={`chat ${isUser ? 'chat-end' : 'chat-start'} items-start mb-6`}>
      {!isUser && <div className="chat-image self-start sticky top-0 p-2"><AIAvatar /></div>}
      <div 
        className={`${
          isUser 
            ? 'chat-bubble bg-base-200' 
            : 'px-0 bg-transparent'
        } max-w-[90%] break-words ${isUser ? 'shadow-sm' : ''}`}
      >
        <div className={`prose prose-sm max-w-none ${!isUser && 'text-base-content'}`}>
          {isUser ? (
            <div className="whitespace-pre-wrap">{message.content}</div>
          ) : (
            <>
              <StreamingMessage content={message.content} />
              <div className="mt-2">
                <CopyButton content={message.content} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatWindow; 