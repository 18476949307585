import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, useScroll, useTransform, useSpring, AnimatePresence } from 'framer-motion';
import niktalogo from '../niktalogo.svg';
import logo from '../logo.svg';
import ParticleEffect from '../components/ParticleEffect';

const CustomCursor = () => {
  const cursorRef = useRef(null);
  const cursorBorderRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousemove', (e) => {
      if (cursorRef.current && cursorBorderRef.current) {
        cursorRef.current.style.left = e.clientX + 'px';
        cursorRef.current.style.top = e.clientY + 'px';
        cursorBorderRef.current.style.left = e.clientX + 'px';
        cursorBorderRef.current.style.top = e.clientY + 'px';
      }
    });

    return () => {
      document.removeEventListener('mousemove', () => {});
    };
  }, []);

  return (
    <>
      <div
        ref={cursorRef}
        className="fixed w-3 h-3 bg-gradient-to-r from-cyan-400 to-teal-400 rounded-full pointer-events-none z-50 -translate-x-1/2 -translate-y-1/2 mix-blend-screen"
      />
      <div
        ref={cursorBorderRef}
        className="fixed w-8 h-8 rounded-full border-2 border-cyan-400/30 pointer-events-none z-50 -translate-x-1/2 -translate-y-1/2 transition-transform duration-200"
      />
    </>
  );
};

const FloatingElement = ({ delay = 0, duration = 20, className }) => (
  <motion.div
    animate={{
      y: [0, -20, 0],
      rotate: [0, 360],
      scale: [1, 1.1, 1],
    }}
    transition={{
      duration,
      repeat: Infinity,
      delay,
      ease: "linear",
    }}
    className={className}
  />
);

const WaveAnimation = () => (
  <div className="absolute bottom-0 left-0 right-0 overflow-hidden leading-none">
    <svg className="relative block w-full h-[100px]" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path 
        d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" 
        className="fill-white/30"
      />
    </svg>
  </div>
);

const Testimonial = ({ text, author, delay = 0 }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay }}
    viewport={{ once: true }}
    className="bg-white/80 backdrop-blur-xl rounded-2xl p-6 shadow-lg relative group hover:shadow-2xl transition-all duration-500"
  >
    <div className="absolute inset-0 bg-gradient-to-r from-cyan-500/10 to-purple-500/10 rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
    <p className="text-slate-600 mb-4 relative z-10">{text}</p>
    <p className="text-slate-800 font-medium relative z-10">— {author}</p>
  </motion.div>
);

const LandingPage = () => {
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll();
  
  const backgroundY = useSpring(
    useTransform(scrollYProgress, [0, 1], ['0%', '50%']),
    { stiffness: 100, damping: 30 }
  );

  return (
    <div ref={containerRef} className="min-h-screen bg-[#f8fafc] text-slate-800 overflow-x-hidden cursor-none">
      <ParticleEffect />
      <CustomCursor />
      
      {/* Фоновые градиенты */}
      <div className="fixed inset-0 pointer-events-none">
        <div className="absolute inset-0 bg-gradient-to-b from-cyan-50 to-purple-50/30"></div>
        <motion.div style={{ y: backgroundY }} className="absolute inset-0">
          <div className="absolute w-[800px] h-[800px] rounded-full bg-gradient-to-r from-cyan-100/30 via-teal-100/30 to-purple-100/30 blur-[80px] -top-[200px] -right-[200px]"></div>
          <div className="absolute w-[600px] h-[600px] rounded-full bg-gradient-to-r from-teal-100/30 via-cyan-100/30 to-purple-100/30 blur-[80px] -bottom-[150px] -left-[150px]"></div>
        </motion.div>

        {/* Плавающие элементы */}
        <FloatingElement 
          delay={0}
          className="absolute w-32 h-32 rounded-full bg-gradient-to-r from-cyan-200/20 to-teal-200/20 blur-xl top-[20%] left-[20%]"
        />
        <FloatingElement 
          delay={5}
          className="absolute w-24 h-24 rounded-full bg-gradient-to-r from-purple-200/20 to-cyan-200/20 blur-xl top-[60%] right-[10%]"
        />
        <FloatingElement 
          delay={2}
          className="absolute w-40 h-40 rounded-full bg-gradient-to-r from-teal-200/20 to-purple-200/20 blur-xl bottom-[30%] left-[15%]"
        />
      </div>

      {/* Навигация */}
      <motion.nav
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        className="fixed top-0 left-0 right-0 z-50"
      >
        <div className="mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <motion.div 
              whileHover={{ scale: 1.01 }}
              className="relative group"
            >
              <img src={logo} alt="N.CHAT" className="h-8 w-auto" />
            </motion.div>

            <motion.button
              whileHover={{ scale: 1.05 }}
              onClick={() => navigate('/login')}
              className="px-6 py-2 rounded-full bg-white/70 backdrop-blur-sm border border-cyan-200/50 shadow-sm hover:shadow-md transition-all duration-300"
            >
              Войти
            </motion.button>
          </div>
        </div>
      </motion.nav>

      {/* Hero секция */}
      <section className="min-h-screen pt-32 pb-20 px-6 relative">
        <div className="max-w-6xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center mb-16"
          >
            <h1 className="text-5xl md:text-7xl font-bold mb-8 leading-tight">
              <span className="text-slate-900">Общайтесь с</span><br />
              <span className="bg-gradient-to-r from-cyan-500 via-teal-400 to-cyan-400 text-transparent bg-clip-text">
                искусственным интеллектом
              </span>
            </h1>
            
            <p className="text-xl text-slate-600 max-w-2xl mx-auto mb-12">
              Мгновенные ответы на любые вопросы, помощь в работе и творческие идеи
            </p>
          </motion.div>

          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.3 }}
            className="flex justify-center gap-8 mb-16"
          >
            <div className="flex items-center gap-2 bg-white/40 backdrop-blur-sm rounded-full px-4 py-2">
              <div className="w-3 h-3 bg-green-400 rounded-full animate-pulse" />
              <span className="text-sm font-medium">Доступно в России</span>
            </div>
            <div className="flex items-center gap-2 bg-white/40 backdrop-blur-sm rounded-full px-4 py-2">
              <div className="w-3 h-3 bg-blue-400 rounded-full animate-pulse" />
              <span className="text-sm font-medium">Без VPN</span>
            </div>
            <div className="flex items-center gap-2 bg-white/40 backdrop-blur-sm rounded-full px-4 py-2">
              <div className="w-3 h-3 bg-purple-400 rounded-full animate-pulse" />
              <span className="text-sm font-medium">Быстрее ChatGPT</span>
            </div>
          </motion.div>

          {/* Демонстрация интерфейса */}
          <motion.div 
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="relative max-w-4xl mx-auto"
          >
            <div className="bg-white/70 backdrop-blur-xl rounded-2xl p-6 shadow-xl shadow-slate-200/50 border border-slate-200/50">
              <div className="flex items-center gap-4 mb-6">
                <div className="w-3 h-3 rounded-full bg-red-400"></div>
                <div className="w-3 h-3 rounded-full bg-yellow-400"></div>
                <div className="w-3 h-3 rounded-full bg-green-400"></div>
              </div>
              
              {/* Демо чата */}
              <div className="space-y-6">
                <div className="flex items-start gap-4">
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ type: "spring", duration: 0.5 }}
                    className="w-10 h-10 rounded-full bg-gradient-to-r from-cyan-500 to-teal-500 flex items-center justify-center text-white text-lg font-bold"
                  >
                    N
                  </motion.div>
                  <motion.div 
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="flex-1"
                  >
                    <div className="bg-white rounded-2xl p-4 shadow-sm">
                      <p className="text-slate-800">Привет! Я - умный AI-ассистент, который:</p>
                      <motion.ul 
                        className="mt-2 space-y-2"
                        initial="hidden"
                        animate="visible"
                        variants={{
                          visible: {
                            transition: {
                              staggerChildren: 0.1
                            }
                          }
                        }}
                      >
                        {[
                          "🚀 Работает в 2 раза быстрее ChatGPT",
                          "🔒 Полностью безопасен и доступен в России",
                          "🎯 Даёт более точные ответы",
                          "📚 Имеет доступ к актуальным данным",
                          "🎨 Помогает в создании контента"
                        ].map((text, i) => (
                          <motion.li
                            key={i}
                            variants={{
                              hidden: { opacity: 0, x: -20 },
                              visible: { opacity: 1, x: 0 }
                            }}
                            className="flex items-center gap-2 text-slate-700"
                          >
                            <span>{text}</span>
                          </motion.li>
                        ))}
                      </motion.ul>
                    </div>
                  </motion.div>
                </div>

                <motion.div 
                  className="flex items-start gap-4 justify-end"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1 }}
                >
                  <div className="flex-1">
                    <div className="bg-gradient-to-r from-cyan-500 to-teal-500 rounded-2xl p-4 text-white ml-auto max-w-md">
                      <p>Можешь показать пример твоих возможностей?</p>
                    </div>
                  </div>
                </motion.div>

                <motion.div 
                  className="flex items-start gap-4"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 1.5 }}
                >
                  <div className="w-10 h-10 rounded-full bg-gradient-to-r from-cyan-500 to-teal-500 flex items-center justify-center text-white text-lg font-bold">
                    N
                  </div>
                  <div className="flex-1">
                    <div className="bg-white rounded-2xl p-4 shadow-sm space-y-4">
                      <div className="flex gap-4">
                        <div className="flex-1 space-y-2">
                          <div className="h-40 bg-gradient-to-r from-purple-100 to-pink-100 rounded-lg flex items-center justify-center">
                            <span className="text-4xl">🎨</span>
                          </div>
                          <p className="text-sm text-center text-slate-600">Творческие идеи</p>
                        </div>
                        <div className="flex-1 space-y-2">
                          <div className="h-40 bg-gradient-to-r from-blue-100 to-cyan-100 rounded-lg flex items-center justify-center">
                            <span className="text-4xl">📊</span>
                          </div>
                          <p className="text-sm text-center text-slate-600">Анализ данных</p>
                        </div>
                        <div className="flex-1 space-y-2">
                          <div className="h-40 bg-gradient-to-r from-green-100 to-teal-100 rounded-lg flex items-center justify-center">
                            <span className="text-4xl">✍️</span>
                          </div>
                          <p className="text-sm text-center text-slate-600">Написание текстов</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>

              {/* Поле ввода */}
              <div className="mt-6">
                <div className="relative">
                  <input 
                    type="text" 
                    placeholder="Введите ваше сообщение..."
                    className="w-full px-6 py-4 bg-white rounded-full border border-slate-200 focus:outline-none focus:ring-2 focus:ring-blue-500/20"
                  />
                  <button className="absolute right-2 top-1/2 -translate-y-1/2 p-2 rounded-full bg-gradient-to-r from-cyan-500 to-teal-500 text-white">
                    <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            {/* Декоративные элементы */}
            <div className="absolute -top-10 -right-10 w-20 h-20 bg-gradient-to-r from-pink-200 to-purple-200 rounded-full blur-xl opacity-60"></div>
            <div className="absolute -bottom-10 -left-10 w-20 h-20 bg-gradient-to-r from-blue-200 to-purple-200 rounded-full blur-xl opacity-60"></div>
          </motion.div>
        </div>
      </section>

      {/* Секция возможностей */}
      <section className="py-20 px-6">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              {
                icon: "🚀",
                title: "В 5 раз быстрее",
                description: "Мгновенные ответы без долгого ожидания",
                gradient: "from-pink-500 to-rose-500"
              },
              {
                icon: "🔒",
                title: "Доступно в России",
                description: "Работает без VPN и других ограничений",
                gradient: "from-cyan-500 to-blue-500"
              },
              {
                icon: "🎯",
                title: "Точные ответы",
                description: "Используем передовые алгоритмы и актуальные данные",
                gradient: "from-green-500 to-teal-500"
              }
            ].map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
                whileHover={{ y: -5, scale: 1.02 }}
                className="relative group"
              >
                <div className="absolute inset-0 bg-gradient-to-r opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-2xl blur-xl -z-10"
                     style={{
                       backgroundImage: `linear-gradient(to right, var(--tw-gradient-stops))`,
                       '--tw-gradient-from': feature.gradient.split(' ')[0],
                       '--tw-gradient-to': feature.gradient.split(' ')[2]
                     }}
                />
                <div className="bg-white/70 backdrop-blur-xl rounded-2xl p-6 shadow-lg shadow-slate-200/50 border border-slate-200/50">
                  <div className="text-4xl mb-4 transform group-hover:scale-110 transition-transform duration-300">{feature.icon}</div>
                  <h3 className="text-xl font-bold mb-2 text-slate-900">{feature.title}</h3>
                  <p className="text-slate-600">{feature.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Wow-эффект секция */}
      <section className="py-32 px-6 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-slate-50/0 via-cyan-50/30 to-purple-50/30" />
        
        {/* Анимированные круги */}
        <motion.div
          animate={{
            scale: [1, 1.2, 1],
            rotate: [0, 180, 360],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            ease: "linear"
          }}
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[800px] h-[800px]"
        >
          <div className="absolute inset-0 rounded-full border-2 border-cyan-200/30 animate-pulse" />
          <div className="absolute inset-[100px] rounded-full border-2 border-purple-200/30 animate-pulse delay-300" />
          <div className="absolute inset-[200px] rounded-full border-2 border-teal-200/30 animate-pulse delay-500" />
        </motion.div>

        <div className="max-w-7xl mx-auto relative">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="text-center mb-20"
          >
            <h2 className="text-5xl md:text-6xl font-bold mb-8">
              <span className="bg-gradient-to-r from-cyan-500 via-purple-500 to-teal-500 text-transparent bg-clip-text">
                Революция в мире AI
              </span>
            </h2>
            <p className="text-xl text-slate-600 max-w-3xl mx-auto">
              Мы создали нечто большее, чем просто чат-бот. Это ваш личный AI-ассистент с продвинутым пониманием контекста и способностью к обучению.
            </p>
          </motion.div>

          {/* 3D Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-20">
            {[
              {
                icon: "🧠",
                title: "Нейронная сеть",
                description: "Собственная архитектура на базе трансформеров",
                gradient: "from-purple-500 to-pink-500"
              },
              {
                icon: "⚡️",
                title: "Скорость света",
                description: "Ответ за 1-5 секунд благодаря оптимизированной инференции",
                gradient: "from-cyan-500 to-blue-500"
              },
              {
                icon: "🎯",
                title: "Знания 99.9%",
                description: "Знает почти все, умеет даже гуглить, так что может помочь в любой ситуации",
                gradient: "from-green-500 to-teal-500"
              }
            ].map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
                whileHover={{ 
                  scale: 1.05,
                  rotateY: 10,
                  z: 50
                }}
                className="group perspective"
              >
                <div className="relative transform-gpu transition-all duration-500 group-hover:rotate-y-12">
                  <div className="absolute inset-0 bg-gradient-to-r rounded-2xl blur-xl opacity-0 group-hover:opacity-70 transition-opacity duration-500"
                       style={{
                         backgroundImage: `linear-gradient(to right, var(--tw-gradient-stops))`,
                         '--tw-gradient-from': feature.gradient.split(' ')[0],
                         '--tw-gradient-to': feature.gradient.split(' ')[2]
                       }}
                  />
                  <div className="bg-white/80 backdrop-blur-xl rounded-2xl p-8 shadow-lg relative z-10 border border-slate-200/50">
                    <div className="text-6xl mb-6 transform group-hover:scale-110 transition-transform duration-500">{feature.icon}</div>
                    <h3 className="text-2xl font-bold mb-4 text-slate-900">{feature.title}</h3>
                    <p className="text-slate-600">{feature.description}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Отзывы */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <Testimonial 
              text="Невероятно быстрые и точные ответы. Намного удобнее, чем ChatGPT!"
              author="Александр К., разработчик"
              delay={0.1}
            />
            <Testimonial 
              text="Использую для работы каждый день. Огромная экономия времени!"
              author="Мария С., дизайнер"
              delay={0.2}
            />
            <Testimonial 
              text="Лучший AI-ассистент, который я когда-либо использовал."
              author="Дмитрий В., предприниматель"
              delay={0.3}
            />
            <Testimonial 
              text="Помогает в написании текстов и генерации идей. Просто космос!"
              author="Елена П., маркетолог"
              delay={0.4}
            />
          </div>

          {/* Статистика */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="mt-20 grid grid-cols-2 md:grid-cols-4 gap-8"
          >
            {[
              { number: "100+", label: "Пользователей" },
              { number: "5s", label: "Скорость ответа" },
              { number: "99.9%", label: "Знаний" },
              { number: "24/7", label: "Поддержка" }
            ].map((stat, index) => (
              <div key={stat.label} className="text-center">
                <motion.div
                  initial={{ scale: 0 }}
                  whileInView={{ scale: 1 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true }}
                  className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-cyan-500 to-teal-500 text-transparent bg-clip-text mb-2"
                >
                  {stat.number}
                </motion.div>
                <p className="text-slate-600">{stat.label}</p>
              </div>
            ))}
          </motion.div>
        </div>
      </section>

      {/* CTA секция */}
      <section className="py-20 px-6 relative overflow-hidden">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="max-w-4xl mx-auto text-center relative z-10"
        >
          <h2 className="text-4xl md:text-5xl font-bold mb-8">
            <span className="bg-gradient-to-r from-cyan-500 via-teal-400 to-cyan-400 text-transparent bg-clip-text">
              Начните общение прямо сейчас
            </span>
          </h2>
          <p className="text-xl text-slate-600 mb-12 max-w-2xl mx-auto">
            Присоединяйтесь к тысячам пользователей, которые уже используют N.CHAT
          </p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate('/register')}
            className="px-8 py-4 bg-gradient-to-r from-cyan-500 via-teal-400 to-cyan-400 text-white rounded-full text-lg font-medium shadow-lg shadow-blue-600/20 hover:shadow-xl hover:shadow-blue-600/30 transition-all duration-300 mb-16"
          >
            Создать аккаунт бесплатно
          </motion.button>

          {/* Перемещенный и отцентрированный блок "Сделано никтой" */}
          <div className="flex justify-center">
            <motion.a
              href="https://nikta.ai/"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex flex-col items-center"
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
            >
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="relative"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-cyan-200/30 to-teal-200/30 blur-2xl rounded-full transform scale-150"></div>
                <img src={niktalogo} alt="Nikta Logo" className="w-24 h-24 relative mb-4" />
              </motion.div>
              <span className="px-6 py-2 rounded-full bg-white/70 backdrop-blur-sm border border-slate-200/50 text-xl font-medium text-slate-700">
                Сделано никтой
              </span>
            </motion.a>
          </div>
        </motion.div>
      </section>

      {/* Footer */}
      <footer className="py-10 px-6 border-t border-slate-200 bg-white/50 backdrop-blur-sm">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="text-left">
              <div className="mb-4">
                <img 
                  src={logo} 
                  alt="N.CHAT" 
                  style={{ height: '24px', width: 'auto', maxWidth: '100%' }} 
                  className="object-contain"
                />
              </div>
              <div className="text-slate-600 space-y-2 text-sm">
                <p>ООО "ЗВЕНО"</p>
                <p>ИНН 5260491864 КПП 526001001</p>
                <p>603093, Нижегородская область, г. Нижний Новгород,</p>
                <p>ул. Родионова, д. 23а к. 2, офис 303</p>
              </div>
            </div>
            <div className="text-right md:text-right text-slate-600">
              <p className="text-sm">© 2024 - 2025 NIKTA. Все права защищены.</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage; 