import React from 'react';

const ThemeModal = ({ isOpen, onClose, themes, currentTheme, onThemeChange }) => {
  if (!isOpen) return null;

  return (
    <div className="modal modal-open">
      <div className={`fixed inset-0 w-[85vw] md:w-64 bg-base-100 flex flex-col`}>
        <div className="p-4 border-b border-base-300 flex items-center bg-base-100">
          <h3 className="text-lg font-['Unbounded']">Выберите тему</h3>
        </div>
        
        <div className="flex-1 overflow-y-auto p-2">
          <ul className="menu menu-md p-0 gap-1">
            {themes.map((theme) => (
              <li key={theme.id}>
                <button
                  className={`flex items-center gap-3 px-4 py-3 hover:bg-base-200 ${
                    currentTheme === theme.id ? 'bg-primary text-primary-content hover:bg-primary' : ''
                  }`}
                  onClick={() => onThemeChange(theme.id)}
                >
                  <input
                    type="radio"
                    name="theme"
                    className={`radio radio-sm ${
                      currentTheme === theme.id ? 'radio-primary-content' : 'radio-primary'
                    }`}
                    checked={currentTheme === theme.id}
                    onChange={() => {}}
                  />
                  <span className="font-medium">{theme.name}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className="p-4 border-t border-base-300 bg-base-100">
          <button 
            className="btn btn-primary w-full"
            onClick={onClose}
          >
            Закрыть
          </button>
        </div>
      </div>
      <div className="modal-backdrop" onClick={onClose}>
        <button hidden>close</button>
      </div>
    </div>
  );
};

export default ThemeModal; 