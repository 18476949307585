import React, { useState, useEffect } from 'react';
import ChatList from '../components/Chat/ChatList';
import ChatWindow from '../components/Chat/ChatWindow';
import { API_ENDPOINTS, getAxiosInstance } from '../config/api';
import axios from 'axios';

const ChatPage = ({ onLogout }) => {
  const [chats, setChats] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (window.innerWidth > 768) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchChats();
  }, []);

  const fetchChats = async () => {
    try {
      const api = getAxiosInstance();
      const response = await api.get(API_ENDPOINTS.CHATS);
      setChats(response.data);
    } catch (error) {
      console.error('Ошибка загрузки чатов:', error);
      if (error.response?.status === 401) {
        onLogout();
      }
    }
  };

  const handleCreateChat = (newChat) => {
    if (Array.isArray(newChat)) {
      setChats(newChat);
    } else {
      setChats([...chats, newChat]);
    }
  };

  const handleUpdateChatTitle = async (chatId, newTitle) => {
    try {
      const api = getAxiosInstance();
      const response = await api.patch(`${API_ENDPOINTS.CHATS}/${chatId}`, {
        title: newTitle
      });

      if (response.data) {
        setChats(chats.map(chat => 
          chat.id === chatId ? { ...chat, title: newTitle } : chat
        ));
      }
    } catch (error) {
      console.error('Ошибка обновления названия чата:', error);
      if (error.response?.status === 401) {
        onLogout();
      }
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Мобильная верхняя панель */}
      {isMobile && (
        <div className="fixed top-0 left-0 right-0 h-14 bg-base-100 border-b border-base-200 flex items-center px-4 z-50">
          <button
            onClick={toggleSidebar}
            className="btn btn-ghost btn-sm px-0 hover:bg-transparent"
            aria-label="Меню"
          >
            {isSidebarOpen ? (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            )}
          </button>
          <h1 className="text-lg font-bold font-['Unbounded'] ml-4">N.CHAT</h1>
        </div>
      )}

      {/* Сайдбар */}
      <div 
        className={`
          ${isMobile ? 'fixed inset-y-0 left-0 z-40' : 'relative'}
          ${isMobile && !isSidebarOpen ? '-translate-x-full' : 'translate-x-0'}
          transition-transform duration-300 ease-in-out
          h-full shadow-lg
        `}
      >
        <ChatList
          chats={chats}
          activeChat={activeChat}
          onSelectChat={setActiveChat}
          onCreateChat={handleCreateChat}
          onLogout={onLogout}
          isMobileView={isMobile}
          onCloseSidebar={() => setIsSidebarOpen(false)}
        />
      </div>

      {/* Основной контент */}
      <div className={`flex-1 min-w-0 ${isMobile ? 'pt-14' : ''}`}>
        <ChatWindow 
          chatId={activeChat}
          onUpdateChatTitle={handleUpdateChatTitle}
          onCreateChat={handleCreateChat}
          setActiveChat={setActiveChat}
        />
      </div>

      {/* Затемнение фона при открытом сайдбаре на мобильных */}
      {isMobile && isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}
    </div>
  );
};

export default ChatPage;