import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ThemeSelector from '../Layout/ThemeSelector';
import { API_ENDPOINTS, getAxiosInstance } from '../../config/api';
import axios from 'axios';

const ChatList = ({ chats, activeChat, onSelectChat, onCreateChat, onLogout, isMobileView, onCloseSidebar }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    try {
      const api = getAxiosInstance();
      const response = await api.get(API_ENDPOINTS.ME);
      setUser(response.data);
    } catch (error) {
      console.error('Ошибка получения информации о пользователе:', error);
      if (error.response?.status === 401) {
        handleLogout();
      }
    }
  };

  const handleCreateChat = async () => {
    try {
      const api = getAxiosInstance();
      const response = await api.post(API_ENDPOINTS.CHATS, {
        title: 'Новый чат',
      });

      const newChat = response.data;
      onCreateChat(newChat);
      onSelectChat(newChat.id);
      if (isMobileView) {
        onCloseSidebar();
      }
    } catch (error) {
      console.error('Ошибка создания чата:', error);
      if (error.response?.status === 401) {
        handleLogout();
      }
    }
  };

  const handleDeleteChat = async (chatId, e) => {
    e.stopPropagation();
    try {
      const api = getAxiosInstance();
      await api.delete(`${API_ENDPOINTS.CHATS}/${chatId}`);
      
      const updatedChats = chats.filter(chat => chat.id !== chatId);
      onCreateChat(updatedChats);
      
      if (activeChat === chatId) {
        const nextChat = updatedChats[0] || null;
        onSelectChat(nextChat ? nextChat.id : null);
      }
    } catch (error) {
      console.error('Ошибка удаления чата:', error);
      if (error.response?.status === 401) {
        handleLogout();
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    onLogout();
    navigate('/login');
  };

  const handleChatSelect = (chatId) => {
    onSelectChat(chatId);
    if (isMobileView) {
      onCloseSidebar();
    }
  };

  return (
    <div className={`flex flex-col h-full bg-base-200 ${isMobileView ? 'w-[85vw]' : 'w-64'} border-r border-base-300`}>
      <div className="flex-none p-4 border-b border-base-300">
        <div className="flex items-center justify-between mb-4">
          <h1 className="text-2xl font-bold font-['Unbounded']">N.CHAT</h1>
          {isMobileView && (
            <button
              onClick={onCloseSidebar}
              className="btn btn-ghost btn-sm p-0 hover:bg-transparent"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          )}
        </div>
        <button
          onClick={handleCreateChat}
          className="btn btn-primary w-full gap-2"
          aria-label="Новый чат"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
          </svg>
          Новый чат
        </button>
      </div>
      
      <div className="flex-grow overflow-y-auto p-4 pt-4">
        {chats.map((chat) => (
          <div
            key={chat.id}
            onClick={() => handleChatSelect(chat.id)}
            className={`cursor-pointer p-3 mb-2 rounded-lg transition-colors border border-base-300 hover:border-primary flex justify-between items-center group ${
              activeChat === chat.id
                ? 'bg-primary text-primary-content border-primary'
                : 'hover:bg-base-300'
            }`}
          >
            <div className="truncate flex-1 mr-2">{chat.title}</div>
            <button
              onClick={(e) => handleDeleteChat(chat.id, e)}
              className={`btn btn-ghost btn-xs ${activeChat === chat.id ? 'text-primary-content hover:bg-primary-focus' : 'text-base-content hover:bg-base-300'}`}
              aria-label="Удалить чат"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </button>
          </div>
        ))}
      </div>

      <div className="flex-none p-4 border-t border-base-300">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2 justify-between">
            <div className="flex items-center gap-2">
              <div className="avatar placeholder">
                <div className="bg-neutral text-neutral-content rounded-full w-8">
                  <span className="text-xs">{user?.username?.[0]?.toUpperCase() || 'N'}</span>
                </div>
              </div>
              <span className="font-medium">{user?.username || 'Пользователь'}</span>
            </div>
            <ThemeSelector />
          </div>
          
          <button
            onClick={handleLogout}
            className="btn btn-outline btn-error w-full btn-sm gap-2"
            aria-label="Выйти"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
            Выйти
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatList; 