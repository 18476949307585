// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&family=Onest:wght@400;500;600;700;800;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;600;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Импорт шрифтов из Google Fonts */

/* Импорт JetBrains Mono */

/* Добавляем импорт Space Grotesk */

/* Определение переменных с шрифтами */
:root {
  --font-primary: 'Inter', system-ui, -apple-system, sans-serif;
  --font-headers: 'Space Grotesk', var(--font-primary);
  --font-mono: 'JetBrains Mono', ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo, Monaco, 'Courier New', monospace;
}

/* Базовые стили для body */
body {
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Стили для заголовков */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-headers);
}

/* Стили для моноширинного текста */
code, pre {
  font-family: var(--font-mono);
} `, "",{"version":3,"sources":["webpack://./src/styles/fonts.css"],"names":[],"mappings":"AAAA,mCAAmC;;AAGnC,0BAA0B;;AAG1B,mCAAmC;;AAGnC,sCAAsC;AACtC;EACE,6DAA6D;EAC7D,oDAAoD;EACpD,wHAAwH;AAC1H;;AAEA,2BAA2B;AAC3B;EACE,gCAAgC;EAChC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA,yBAAyB;AACzB;EACE,gCAAgC;AAClC;;AAEA,mCAAmC;AACnC;EACE,6BAA6B;AAC/B","sourcesContent":["/* Импорт шрифтов из Google Fonts */\n@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&family=Onest:wght@400;500;600;700;800;900&display=swap');\n\n/* Импорт JetBrains Mono */\n@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;600;700&display=swap');\n\n/* Добавляем импорт Space Grotesk */\n@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');\n\n/* Определение переменных с шрифтами */\n:root {\n  --font-primary: 'Inter', system-ui, -apple-system, sans-serif;\n  --font-headers: 'Space Grotesk', var(--font-primary);\n  --font-mono: 'JetBrains Mono', ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo, Monaco, 'Courier New', monospace;\n}\n\n/* Базовые стили для body */\nbody {\n  font-family: var(--font-primary);\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n/* Стили для заголовков */\nh1, h2, h3, h4, h5, h6 {\n  font-family: var(--font-headers);\n}\n\n/* Стили для моноширинного текста */\ncode, pre {\n  font-family: var(--font-mono);\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
