import React, { useState, useEffect } from 'react';
import ThemeModal from './ThemeModal';

const ThemeSelector = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen]);

  const themes = [
    { id: "light", name: "Светлая" },
    { id: "dark", name: "Тёмная" },
    { id: "cupcake", name: "Капкейк" },
    { id: "bumblebee", name: "Шмель" },
    { id: "emerald", name: "Изумруд" },
    { id: "corporate", name: "Корпоративная" },
    { id: "synthwave", name: "Синтвейв" },
    { id: "retro", name: "Ретро" },
    { id: "cyberpunk", name: "Киберпанк" },
    { id: "valentine", name: "Валентин" },
    { id: "halloween", name: "Хэллоуин" },
    { id: "garden", name: "Сад" },
    { id: "forest", name: "Лес" },
    { id: "aqua", name: "Аква" },
    { id: "lofi", name: "Ло-фай" },
    { id: "pastel", name: "Пастель" },
    { id: "fantasy", name: "Фэнтези" },
    { id: "wireframe", name: "Каркас" },
    { id: "black", name: "Чёрная" },
    { id: "luxury", name: "Люкс" },
    { id: "dracula", name: "Дракула" },
    { id: "cmyk", name: "CMYK" },
    { id: "autumn", name: "Осень" },
    { id: "business", name: "Бизнес" },
    { id: "acid", name: "Кислота" },
    { id: "lemonade", name: "Лимонад" },
    { id: "night", name: "Ночь" },
    { id: "coffee", name: "Кофе" },
    { id: "winter", name: "Зима" },
    { id: "dim", name: "Тусклая" },
    { id: "nord", name: "Норд" },
    { id: "sunset", name: "Закат" }
  ];

  const handleThemeChange = (themeId) => {
    document.documentElement.setAttribute('data-theme', themeId);
    localStorage.setItem('theme', themeId);
    setIsModalOpen(false);
  };

  const currentTheme = document.documentElement.getAttribute('data-theme');

  return (
    <>
      <button 
        className="btn btn-ghost btn-sm" 
        onClick={() => setIsModalOpen(true)}
        aria-label="Выбрать тему оформления"
      >
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          fill="none" 
          viewBox="0 0 24 24" 
          strokeWidth={1.5} 
          stroke="currentColor" 
          className="w-5 h-5"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
          />
        </svg>
      </button>

      <ThemeModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        themes={themes}
        currentTheme={currentTheme}
        onThemeChange={handleThemeChange}
      />
    </>
  );
};

export default ThemeSelector; 