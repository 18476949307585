import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL || 'https://n-chat.ru/api';

export const API_ENDPOINTS = {
  AUTH: BASE_URL,
  REGISTER: `${BASE_URL}/register`,
  ME: `${BASE_URL}/users/me`,
  CHATS: `${BASE_URL}/chats`,
  MESSAGES: (chatId) => `${BASE_URL}/chats/${chatId}/messages`,
};

export const apiConfig = {
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

// Функция для получения настроенного экземпляра axios с текущим токеном
export const getAxiosInstance = () => {
  const token = localStorage.getItem('token');
  return axios.create({
    ...apiConfig,
    headers: {
      ...apiConfig.headers,
      'Authorization': token ? `Bearer ${token}` : undefined
    }
  });
};

export default API_ENDPOINTS; 